<template>
  <div class="liucheng">
    <subtitle>
      <div slot="above-title">加盟流程</div>
    </subtitle>
    <div style="width: 1100px; margin: 0 auto">
      <img class="img1" src="~assets/img/jiameng/liucheng.png" width="100%" alt="">
    </div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'

  export default {
    name: "LiuCheng",
    components: { 
      Subtitle
    }
  }
</script>

<style scoped>
  .liucheng {
    padding: 50px 0;
  }

  .img1 {
    margin-top: 60px;
  }
</style>
