<template>
  <div class="shili">
    <subtitle>
      <div slot="above-title">品牌实力</div>
    </subtitle>
    <div style="margin-top: 30px">
      <tab-control-btn>
        <tab-control-panel label="大富智慧健康" name="大富智慧健康">
          <img src="~assets/img/jiameng/shili2.png" width="100%" height="100%" alt="">
        </tab-control-panel>

        <tab-control-panel label="智慧医疗" name="智慧医疗">
          <div style="padding: 35px 40px 0px">
            <el-carousel :interval="3000" type="card" height="500px">
              <el-carousel-item>
                <div class="year" style="background-color: #f7d100">2016</div>
                <div style="line-height: 500px; font-size: 24px; color: #848484">2016年 怀远精诚羲黄中医院 成立</div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="year" style="background-color: #e95d00">2017</div>
                <div style="line-height: 500px; font-size: 24px; color: #848484">2017年  便捷式经络检测仪问世</div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="year" style="background-color: #c801b3">2018</div>
                <div style="line-height: 500px; font-size: 24px; color: #848484">2018年 智能血压脉象仪问世</div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="year" style="background-color: #805ac7">2020</div>
                <div style="line-height: 500px; font-size: 24px; color: #848484">2020年 第二代艾灸机器人诞生</div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="year" style="background-color: #2ab760">2021</div>
                <div style="line-height: 500px; font-size: 24px; color: #848484">2021年 互联网医院开始运营</div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </tab-control-panel>

        <tab-control-panel label="企业荣誉" name="企业荣誉"></tab-control-panel>

        <tab-control-panel label="加盟合作" name="加盟合作">
          <div style="position:relative">
            <img src="~assets/img/jiameng/shili1.png" width="100%" height="100%" alt="">
            <div style="position:absolute; z-index:9; left: 6%; top: 16%">
              <div style="font-size: 18px; color: #951e23; font-weight: bold; text-align: left">加盟优势：整店支持体系</div>
              <div style="display: flex; margin-top: 20px">
                <div style="height: 86px; width: 86px; border-radius: 50%; border: 1px solid #dcdcdc; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #6c6c6c; margin: 0 10px">互联网<br>医院</div>
                <div style="height: 86px; width: 86px; border-radius: 50%; border: 1px solid #dcdcdc; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #6c6c6c; margin: 0 10px">上百位<br>名医</div>
                <div style="height: 86px; width: 86px; border-radius: 50%; border: 1px solid #dcdcdc; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #6c6c6c; margin: 0 10px">社区<br>合作</div>
                <div style="height: 86px; width: 86px; border-radius: 50%; border: 1px solid #dcdcdc; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #6c6c6c; margin: 0 10px">非常5+1<br>模式</div>
              </div>
              <div style="display: flex; margin-top: 50px; line-height: 28px">
                <div style="font-size: 18px; color: #951e23; font-weight: bold; text-align: left">加盟模式：</div>
                <div style="font-size: 18px; color: #6c6c6c; text-align: left">店面运营模式，选址科学，形象统一 ，产品齐全，<br>投资成本低，市场空间大，运作简单，客流充足。</div>
              </div>
              <div style="display: flex; margin-top: 30px; line-height: 28px">
                <div style="font-size: 18px; color: #951e23; font-weight: bold; text-align: left">渠道开拓模式：</div>
                <div style="font-size: 18px; color: #6c6c6c; text-align: left">无需店面，直接销售产品，模式灵活，迅速拓展。</div>
              </div>
              <div style="display: flex; margin-top: 30px; line-height: 28px">
                <div style="font-size: 18px; color: #951e23; font-weight: bold; text-align: left">区域独家代理：</div>
                <div style="font-size: 18px; color: #6c6c6c; text-align: left">独享地区的中医康复理疗市场可在市、县、区<br>享受独家代理。</div>
              </div>
            </div>
          </div>
        </tab-control-panel>
      </tab-control-btn>
    </div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'
  import TabControlBtn from 'components/content/tabControl/tabControlBtn/TabControlBtn'
  import TabControlPanel from 'components/content/tabControl/TabControlPanel'

  export default {
    name: "ShiLi",
    components: { 
      Subtitle,
      TabControlBtn,
      TabControlPanel
    }
  }
</script>

<style scoped>
 .shili {
   padding: 50px 0;
   background-image: url('~assets/img/common/bgc.png');
 }

  .el-carousel__item {
    background-color: #fff;
    border: 1px solid #bababa;
    color: #475669;
  }

  .year {
    position: absolute;
    left: -60px;
    height: 60px;
    width: 250px;
    line-height: 60px;
    background-color: #f7d100;
    transform: rotate(-36deg);
    color: #fff;
    font-size: 28px;
    text-align: left;
    padding-left: 75px;
  }
  
  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
</style>
