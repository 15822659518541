<template>
  <div class="youshi-prod">
    <subtitle>
      <div slot="above-title">优势产品</div>
      <div slot="under-title">标杆产品，领跑市场，科技赋能，全方位满足市场需求</div>
    </subtitle>
    <div class="div1">
      <tab-control-left>
        <tab-control-panel label="艾灸机器人" name="艾灸机器人">
          <img src="~assets/img/jiameng/robot.png" alt="" style="margin-top: 20px">
          <div style="font-size: 16px; color: #a40101; font-weight: bold; margin-bottom: 10px">艾灸机器人</div>
          <div style="font-size: 16px; color: #7e7e7e; margin-bottom: 10px">◆ 快速AI寻穴，335个维度儿童成人智能识别   ◆ 安全、高效、智能、易用</div>
          <div style="font-size: 16px; color: #7e7e7e">◆ 智能控温，温和灸、雀啄灸、回旋灸等灸法1:1精准操作</div>
        </tab-control-panel>

        <tab-control-panel label="智能血压脉象仪" name="智能血压脉象仪">
          <div style="display: flex; justify-content: center; padding: 30px 30px 30px 60px">
            <div style="flex: 1">
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 30px">
                <img src="~assets/img/jiameng/icon5.png" alt="" style="object-fit: none; margin-right: 10px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">高精度传感器</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">真实还原心脏跳动血流传播的压力波形，测量精度重复性优于普通血压仪</div>
                </div>
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 30px">
                <img src="~assets/img/jiameng/icon6.png" alt="" style="object-fit: none; margin-right: 10px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">精准血压测量</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">匀速减压过程采用实时闭环反馈系统，准确获取脉搏波形</div>
                </div>
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 30px">
                <img src="~assets/img/jiameng/icon7.png" alt="" style="object-fit: none; margin-right: 10px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">智能加压技术</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">采用智能传感算法，根据测量者血压高低自动调节加压压力</div>
                </div>
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between;">
                <img src="~assets/img/jiameng/icon8.png" alt="" style="object-fit: none; margin-right: 10px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">红外测量温度</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">非接触式测量温度，一键获取人体表温度</div>
                </div>
              </div>
            </div>
            <img src="~assets/img/jiameng/maixiangyi.png" alt="" style="flex: 1; object-fit: none; margin: 0 10px">
            <div style="flex: 1">
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 20px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">脉搏波形展示</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">非实时显示测量波形，直观了解血液在人体的传播情况</div>
                </div>
                <img src="~assets/img/jiameng/icon1.png" alt="" style="object-fit: none; margin-left: 5px">
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 20px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">脉象云端分析</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">人在不同的健康状态下会产生不同的脉象（脉搏波），云端计算协助解析健康信息</div>
                </div>
                <img src="~assets/img/jiameng/icon2.png" alt="" style="object-fit: none; margin-left: 5px">
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between; margin-bottom: 20px">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">智能测量进化</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">用户长期使用可以获得测量正确性、预见性的提高，获得更精准的健康指导</div>
                </div>
                <img src="~assets/img/jiameng/icon3.png" alt="" style="object-fit: none; margin-left: 5px">
              </div>
              <div style="display: flex; align-items: flex-start; justify-content: space-between">
                <div style="text-align: left">
                  <div style="font-size: 18px; font-weight: bold; color: #4c4c4c">蓝牙连接手机</div>
                  <div style="font-size: 16px; color: #7e7e7e; line-height: 24px">蓝牙连接手机</div>
                </div>
                <img src="~assets/img/jiameng/icon4.png" alt="" style="object-fit: none; margin-left: 5px">
              </div>
            </div>
          </div>
        </tab-control-panel>

        <tab-control-panel label="便携式经络检测仪" name="便携式经络检测仪">
          <img src="~assets/img/jiameng/jingluoyi.png" alt="" style="margin-top: 50px; margin-bottom: 30px">
          <div style="font-size: 16px; color: #a40101; font-weight: bold; margin-bottom: 20px">便携式经络检测仪</div>
          <div style="display: flex; justify-content: center">
            <div style="text-align: left; font-size: 16px; color: #7e7e7e; margin-right: 30px">
              <div style="margin-bottom: 10px">● 采用多项自主研发的专利技术</div>
              <div>● 迅速采集人体十二经络原穴的生物电阻信号</div>
            </div>
            <div style="text-align: left; font-size: 16px; color: #7e7e7e">
              <div style="margin-bottom: 10px">● 提高了检测的精准度、稳定性、有效性及抗干扰性</div>
              <div>● 通过人工智能算法反应人体当下的健康状态</div>
            </div>
          </div>
        </tab-control-panel>

        <tab-control-panel label="互联网医院" name="互联网医院">
          <img src="~assets/img/jiameng/robot.png" alt="" style="margin-top: 20px; margin-bottom: 10px">
          <div style="font-size: 16px; color: #a40101; font-weight: bold; margin-bottom: 10px">颠覆传统  科技赋能  健康养生革命风暴</div>
          <div style="font-size: 16px; color: #7e7e7e">领跑全国健康理疗领域应用的智能化产品</div>
        </tab-control-panel>
      </tab-control-left>
    </div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'
  import TabControlLeft from 'components/content/tabControl/tabControlLeft/TabControlLeft'
  import TabControlPanel from 'components/content/tabControl/TabControlPanel'

  export default {
    name: "YoushiProducts",
    components: { 
      Subtitle,
      TabControlLeft,
      TabControlPanel
    }
  }
</script>

<style scoped>
  .youshi-prod {
    padding: 50px 0;
  }

  .div1 {
    margin-top: 40px;
  }
</style>
