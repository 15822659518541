<template>
  <div class="news">
    <subtitle>
      <div slot="above-title">新闻资讯</div>
    </subtitle>
    <div class="div1">
      <div class="xinwen-board" style="margin-right: 40px">
        <div class="board-title">公司新闻</div>
        <div class="li"></div>
        <img src="~assets/img/jiameng/news.png" width="100%" alt="">
        <div v-for="item in newsList_xinwen" style="margin: 20px 0">
          <news-panel>
            <div slot="day">{{item.day}}</div>
            <div slot="year-month">{{item.ym}}</div>
            <div slot="title">{{item.title}}</div>
            <div slot="content">{{item.content}}</div>
          </news-panel>
        </div>  
      </div>
      <div class="dongtai-board" style="margin-left: 40px">
        <div class="board-title">行业动态</div>
        <div class="li"></div>
        <div v-for="item in newsList_dongtai" style="margin: 50px 0 50px 20px">
          <news-panel>
            <div slot="day">{{item.day}}</div>
            <div slot="year-month">{{item.ym}}</div>
            <div slot="title">{{item.title}}</div>
            <div slot="content">{{item.content}}</div>
          </news-panel>
        </div>
      </div>
    </div>
    <div class="btn-more" @click="moreClick">了解更多+</div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'
  import NewsPanel from 'components/content/newsPanel/NewsPanel'

  export default {
    name: "News",
    components: { 
      Subtitle,
      NewsPanel
    },
    data() {
      return {
        newsList_xinwen: [
          {
            day: '12',
            ym: '2020-12',
            title: '大富科技于又获“广东省知识产权保护重点企业”',
            content: '大富科技继获得“广东省知识产权示范企业”、“广东省知识产权优势企业”等荣誉后，大富科技于近期又获得...'
          }
        ],
        newsList_dongtai: [
          {
            day: '09',
            ym: '2020-12',
            title: '大富科技于又获“广东省知识产权保护重点企业”',
            content: '大富科技继获得“广东省知识产权示范企业”、“广东省知识产权优势企业”等荣誉后，大富科技于近期又获得...'
          },
          {
            day: '25',
            ym: '2020-12',
            title: '大富科技于又获“广东省知识产权保护重点企业”',
            content: '大富科技继获得“广东省知识产权示范企业”、“广东省知识产权优势企业”等荣誉后，大富科技于近期又获得...'
          },
          {
            day: '28',
            ym: '2020-12',
            title: '大富科技于又获“广东省知识产权保护重点企业”',
            content: '大富科技继获得“广东省知识产权示范企业”、“广东省知识产权优势企业”等荣誉后，大富科技于近期又获得...'
          },
          {
            day: '29',
            ym: '2020-12',
            title: '大富科技于又获“广东省知识产权保护重点企业”',
            content: '大富科技继获得“广东省知识产权示范企业”、“广东省知识产权优势企业”等荣誉后，大富科技于近期又获得...'
          }
        ]
      }
    },
    methods: {
      moreClick() {
        
      }
    },
  }
</script>

<style scoped>
  .news {
    padding: 50px 0;
    /* text-align: center; */
  }

  .div1 {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .xinwen-board, .dongtai-board {
    width: 560px;
  }

  .board-title {
    font-size: 24px;
    color: #7b7b7b;
    margin-left: 10px;
    text-align: left;
  }

  .li {
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    margin: 15px 0;
  }

  .btn-more {
    font-size: 20px;
    color: #555555;
    border: 1px solid #555555;
    width: 186px;
    text-align: center;
    padding: 12px 0;
    border-radius: 25px;
    margin: 0 auto;
  }
</style>
