<template>
  <div class="tab-control-panel" v-show="visible">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "TabControlPanel",
    data() {
      return {
        visible: true,
      }
    },
    props: {
      name: {
        type: String
      },
      label: {
        type: String,
        default: ''
      }
    },
    mounted() {
      this.updateNav()
    },
    watch: {
      label() {
        this.updateNav()
      }
    },
    methods: {
      updateNav() {
        this.$parent.updateNav()
      },
    }
  }
</script>

<style scoped>

</style>
