<template>
  <div class="jiameng">
    <div class="top">
      <div class="banner"></div>
      <div class="bar"></div>
    </div>
    <aboutus/>
    <why-choose/>
    <you-shi/>
    <youshi-products/>
    <shi-li/>
    <liu-cheng/>
    <!-- <news/> -->
  </div>
</template>

<script>
  import Aboutus from './childrenCpn/Aboutus'
  import WhyChoose from './childrenCpn/WhyChoose'
  import YouShi from './childrenCpn/YouShi'
  import YoushiProducts from './childrenCpn/YoushiProducts'
  import ShiLi from './childrenCpn/ShiLi'
  import LiuCheng from './childrenCpn/LiuCheng'
  import News from './childrenCpn/News'

  export default {
    name: "Jiameng",
    components: {
      Aboutus,
      WhyChoose,
      YouShi,
      YoushiProducts,
      ShiLi,
      LiuCheng,
      News}
  }
</script>

<style scoped>
  .jiameng {
    text-align: center;
    width: 100%;
    min-width: 1220px;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .top img{
    /* display: flex;
    flex-wrap: wrap; */
    vertical-align: top;
  }

  .banner {
    background: url('~assets/img/jiameng/banner.png') top center no-repeat;
    height: 665px;
    min-width: 1220px;
  }

  .bar {
    background: url('~assets/img/jiameng/bar.png') top center no-repeat;
    height: 79px;
    min-width: 1220px;
  }
</style>
