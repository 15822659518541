<template>
  <div class="why-choose">
    <subtitle>
      <div slot="above-title">为什么选择大富智慧健康</div>
    </subtitle>
    <div class="div1">
      <card isActive="true">
        <img slot="img" src="~assets/img/jiameng/card1.png" width="100%" height="100%" alt="">
        <div slot="title">大趋势</div>
        <div slot="content">2020年-2025年，健康产业复合增长率提升50%</div>
      </card>
      <card>
        <img slot="img" src="~assets/img/jiameng/card2.png" width="100%" height="100%" alt="">
        <div slot="title">大政策</div>
        <div slot="content">十三五规划，2035年前，政府将投资600亿元，助力健康事业发展。</div>
      </card>
      <card>
        <img slot="img" src="~assets/img/jiameng/card3.png" width="100%" height="100%" alt="">
        <div slot="title">大环境</div>
        <div slot="content">5G时代，加速大数据和人工智能发展，促进医疗云、远程医疗等应用落地。</div>
      </card>
    </div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'
  import Card from 'components/content/card/Card'

  export default {
    name: "WhyChoose",
    components: { 
      Subtitle,
      Card 
    }
  }
</script>

<style scoped>
  .why-choose {
    background-color: #fbfafa;
    padding: 50px 0;
  }

  .div1 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
</style>
