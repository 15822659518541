<template>
  <div class="youshi">
    <subtitle>
      <div slot="above-title">加盟优势</div>
      <div slot="under-title">我们为您提供一站式服务</div>
    </subtitle>
    <div style="width: 1183px; margin: 0 auto">
      <img class="img1" src="~assets/img/jiameng/youshi.png" width="100%" height="100%" alt="">
    </div>
  </div>
</template>

<script>
  import Subtitle from 'components/content/subtitle/Subtitle'

  export default {
    name: "YouShi",
    components: { 
      Subtitle
    }
  }
</script>

<style scoped>
  .youshi {
    padding: 60px 0;
    background-image: url('~assets/img/common/bgc.png');
  }

  .img1 {
    margin-top: 60px;
  }
</style>
