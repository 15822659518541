<template>
  <div class="tab-control-left">
    <div class="tabs-title">
      <!--标签页标题，我们使用v-for进行渲染-->
      <div class="tabs" v-for="(item,index) in navList">
        <div class="tabs-item"
            :class="{'active': item.name === current}"
            :key="index"
            @click="handleChanged(index)">
          {{item.label}}
        </div>
        <div :class="{'tri': item.name === current}"></div>
      </div>
      
    </div>
    <div class="tabs-content">
      <!--使用slot来进行panel嵌套-->
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TabControlLeft",
    data() {
      return {
        current: 0,
        navList: [],
      }
    },
    props: {
      // value: [String, Number]
    },
    mounted() {
      // console.log(this.getTabs())
    },
    watch: {
      // value(val) {
      //   this.current = val
      // },
      current(val) {
        // 当前tab发生变化时，更新panel的显示状态
        this.updateStatus()
      }
    },
    methods: {
      // 获取panel子组件
      getTabs() {
        return this.$children.filter(item => {
          return item.$options.name == 'TabControlPanel'
        })
      },

      // 显示对应的panel组件内容，并隐藏未被选中的panel
      updateStatus() {
        this.getTabs().forEach(panel => {
          panel.visible = panel.name === this.current
        })
      },

      // 更新tabs
      updateNav() {
        this.navList = []

        this.getTabs().forEach((panel, index) => {
          this.navList.push({
            label: panel.label,
            name: panel.name || index
          })
          // 如果panel没有定义name属性，那么使用index作为panel标识
          if (!panel.name) {
            panel.name = index
          }
          // 设置第一个panel为当前活动panel
          if (index === 0) {
            if (!this.current) {
              this.current = panel.name || index
            }
          }
          this.updateStatus()
        })
      },

      // 点击标签页标题时触发
      handleChanged(index) {

        // 修改当前选中tab
        this.current = this.navList[index].name

        // 更新value
        // this.$emit('input', this.current)

        // 触发自定义事件，供父级使用
        // this.$emit('on-click', this.current)
      }
    }
  }
</script>

<style scoped>
  .tab-control-left {
    display: flex;
    justify-content: center;
  }

  .tabs {
    display: flex;
  }

  .tabs-item {
    height: 124px;
    width: 356px;
    line-height: 124px;
    font-size: 20px;
    color: #7e7e7e;
    background-color: #ededed;
    border: 1px solid #e1dddd;
  }

  .active {
    background-color: #951e23;
    border: none;
    color: #fff;
  }

  .tri {
    position: relative;
    z-index: 9;
    border-style: solid;
    border-width: 18px 22px;
    border-color: transparent transparent transparent #951e23;
    width: 0px;
    height: 0px;
    margin-top: 44px;
  }

  .tabs-content {
    position: relative;
    right: 44px;
    width: 851px;
    background-color: #fafafa;
    /* border: 1px solid #e1dddd; */
    border-top: 1px solid #e1dddd;
    border-bottom: 1px solid #e1dddd;
    border-right: 1px solid #e1dddd;
  }
</style>
