<template>
  <div class="news-panel" @click="newsClick">
    <div class="date">
      <div class="day"><slot name="day"></slot></div>
      <div class="year-month"><slot name="year-month"></slot></div>
    </div>
    <div class="li"></div>
    <div class="right">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsPanel",
    methods: {
      newsClick() {
        
      }
    },
  }
</script>

<style scoped>
  .news-panel {
    display: flex;
    align-items: center;
    /* margin: 40px 0; */
    width: 100%;
  }

  .day {
    font-size: 30px;
    color: #ad1800;
    margin-bottom: 3px;
  }

  .year-month {
    font-size: 14px;
    color: #ad1800;
    white-space: nowrap;
  }

  .li {
    height: 68px;
    border-left: 1px solid #ebebeb;
    margin: 0 15px;
  }

  .right {
    /* background-color: red; */
    text-align: left;
    /* width: 60%; */
    /* height: 68px; */
  }

  .title {
    font-size: 18px;
    color: #000;
    margin-bottom: 5px;
  }

  .content {
    font-size: 14px;
    color: #6c6c6c;
    height: 40px;
    line-height: 20px;
    overflow: hidden;
  }
</style>
